import React from "react"
import { Col, Row, Card, Nav } from "react-bootstrap"
import Layout from "../components/layout"
import Head from "../components/head"
import "bootstrap/dist/css/bootstrap.min.css"
import indexStyles from "../pages/index.module.scss"
import oad from "../img/oad.jpg"
import hackathon from "../img/hackathon.jpg"
import quotegenerator from "../img/quotegenerator.jpg"
import mealFinder from "../img/meal-finder.jpg"

const IndexPage = () => {
  return (
    <div>
      <Layout className="grid">
        <div className={indexStyles.main}>
          <Head title="Home" />
        </div>
        <h1 className="ml-3 mb-4">Portfolio</h1>
        <Col>
          <Card className="mt-3">
            <Row>
              <Col md>
                <Nav.Link
                  href="https://meal-finder-sigma.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-0"
                >
                  <Card.Img variant="top" src={mealFinder} alt="Meal Finder" />
                </Nav.Link>
              </Col>
              <Col md>
                <Card.Body>
                  <Card.Text className="text-dark">
                    <strong>Meal Finder</strong> is a web application made
                    utilizing{" "}
                    <a
                      href="https://www.themealdb.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      TheMealDB API.
                    </a>{" "}
                    It makes a a fetch request based on a user's keyword and
                    returns all sorts of recipes. Vanilla JavaScript and CSS
                    were utilized.
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col>
          <Card className="mt-3">
            <Row>
              <Col md={{ order: 2 }}>
                <Nav.Link
                  href="https://oad-clt-hackathon.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-0"
                >
                  <Card.Img
                    variant="top"
                    src={oad}
                    alt="Opioid Abusers Database"
                  />
                </Nav.Link>
              </Col>
              <Col md={{ order: 1 }}>
                <Card.Body>
                  <Card.Text className="text-dark">
                    <strong>The Opioid Abusers Database</strong> was created at
                    the 2019 Queen City 24-hour Hackathon in Charlotte, NC. My
                    team utilized <strong>Python</strong> with a data set of
                    abusers to notice trends among them. Over night, we created
                    an application with <strong>React + Bootstrap</strong> for
                    medical professionals to determine if a patient could be at
                    risk of abuse and if opioids were the right treatment. We{" "}
                    <a
                      href={hackathon}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      placed second.
                    </a>
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col>
          <Card className="mt-3">
            <Row>
              <Col md>
                <Nav.Link
                  href="https://quote-generator-alpha-snowy.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-0"
                >
                  <Card.Img
                    variant="top"
                    src={quotegenerator}
                    alt="Quote Generator"
                  />
                </Nav.Link>
              </Col>
              <Col>
                <Card.Body>
                  <Card.Text className="text-dark">
                    <strong>Quote Generator</strong> is a web application that
                    retreives random famous quotes via{" "}
                    <a
                      href="https://www.rapidapi.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RapidAPI
                    </a>
                    . I utilized <strong>React</strong> along with the beautiful{" "}
                    <a
                      href="https://designrevision.com/downloads/shards-react/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      React Shards UI kit
                    </a>{" "}
                    for styling.
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Layout>
    </div>
  )
}

export default IndexPage
